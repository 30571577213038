<template>
  <div :class="$style.home">
    <div id="left" :class="$style.base">
      <p :class="$style.title">基础信息</p>
      <div :class="$style.box">
        <div class="layout mb-20">
          <div class="label">
            <span class="required">*</span>
            场馆名称
          </div>
          <div class="value">
            <BjInput v-model="add.name" placeholder="请输入场馆名称" />
          </div>
        </div>
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            场馆封面
          </div>
          <div class="value">
            <selectMaterial :data="add.cover" type="multiple" :allow="['image', 'video']" @change="coverChange" />
            <p class="tip mt-10">
              封面支持图片和视频，最多上传10张，图片建议尺寸750px*560px；大小不超过2M；格式支持JPG、PNG。
            </p>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            一句话描述
          </div>
          <div class="value">
            <BjInput v-model="add.summary" placeholder="请用一句话描述此场馆的特点" />
          </div>
        </div>

        <div class="layout mt-20">
          <div class="label">
            <span class="required">*</span>
            联系电话
          </div>
          <div class="value">
            <BjInput v-model="add.contact_phone" placeholder="请输入场馆方的联系电话" />
            <p class="tip mt-10">联系电话设置仅支持数字和符号“-”，请注意检查，以免用户无法直接点击拨打。</p>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            选择位置
          </div>
          <div class="value" :class="$style.location">
            <BjInput v-model="add.location" placeholder="请选择位置">
              <div slot="suffix">
                <a-popover title="位置信息">
                  <template slot="content">
                    <p>经度：{{ add.longitude }}</p>
                    <p>纬度：{{ add.latitude }}</p>
                    <p>地址：{{ add.real_location }}</p>
                  </template>
                  <i v-if="add.real_location" style="vertical-align: -6px" class="ri-question-line location-icon" />
                </a-popover>
                <span :class="$style.locationbtn" @click="onMapSelect()"><i class="ri-map-pin-line" />选择位置</span>
              </div>
            </BjInput>
            <p class="tip mb-20">请在地图上标注场馆地点的位置，尽量准确。用户可通过此地址进行位置查看或导航等。</p>
          </div>
        </div>

        <div class="layout mb-20">
          <div class="label">
            <span class="required">*</span>
            所属分组
          </div>
          <div class="value">
            <BjSelect
              v-model="add.group_id"
              key-field="value"
              inner-search
              placeholder="请选择内容所属分组"
              value-field="id"
              label-field="name"
              show-all
              :options="groupData"
            />
          </div>
        </div>

        <div class="layout">
          <div class="label">场馆标签</div>
          <div class="value">
            <div :class="$style.tagBox">
              <div :class="$style.tagItem">
                <span v-for="(item, index) in add.tags" :key="index" :class="$style.tagText">
                  <span :class="$style.text" class="ellipsis-width">{{ item }}</span>
                  <i class="ri-close-line" @click="deleteTag(index)" />
                </span>
                <p v-if="!add.tags.length">请添加文化标签</p>
              </div>
              <div :class="$style.tagInput">
                <span v-if="tagShow" :class="$style.location" type="primary" @click="tagShow = false">
                  <i class="ri-add-line" />添加标签
                </span>
                <BjInput v-else v-model="tag" allow-clear class="nomargin" @pressEnter="tagChange" />
              </div>
            </div>
            <p class="tip mt-10">输入标签内容后点击回车键即可添加标签。</p>
          </div>
        </div>
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            允许评论
          </div>
          <div class="value line-40">
            <BjSwitch v-model="add.comment_status" size="default" />
            <p class="tip mt-10">
              评论默认审核机制为“智能审核”，如需修改，请在“
              <span class="primary">评论管理</span>
              ”里面设置审核机制。
            </p>
          </div>
        </div>
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            发布状态
          </div>
          <div class="value line-40">
            <a-radio-group v-model="add.status">
              <a-radio :value="1"> 已发布 </a-radio>
              <a-radio :value="0"> 未发布 </a-radio>
            </a-radio-group>
            <p class="tip mt-10">已发布的场馆用户才能访问，未发布的场馆仅可预览。</p>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.desc">
      <p :class="$style.title">场馆介绍</p>
      <p>
        <span class="required">*</span>
        场馆简介
      </p>
      <div :class="$style.area">
        <a-textarea
          v-model="add.description"
          :auto-size="{ minRows: 6, maxRows: 6 }"
          placeholder="请在这里描述场馆的简介信息…"
          :max-length="500"
        />
        <span :class="$style.num">{{ add.description ? add.description.length : 0 }}/500</span>
      </div>
      <div :class="$style.line" />
      <p>
        <span class="required">*</span>
        场馆介绍
      </p>
      <BjEditor ref="editor" v-model="add.introduce" placeholder="请在这里输入场馆的详细介绍信息。" />
      <div :class="$style.line" />
      <div :class="$style.kv">
        <p :class="$style.kvTitle">
          <span class="required">*</span>
          开放时间
        </p>
        <kvbox
          v-model="add.open_time"
          :placeholder="{ k: '请输入标题，如“最晚入园”', v: '请输入描述内容。如“16:00”' }"
        />
      </div>
      <div :class="$style.line" />
      <div :class="$style.kv">
        <p :class="$style.kvTitle">
          <span class="required">*</span>
          优待政策
        </p>
        <kvbox
          v-model="add.preferential"
          :placeholder="{ k: '请输入标题，如“残疾人”', v: '请输入描述内容。如“凭残疾证免费”' }"
        />
      </div>
      <div :class="$style.line" />
      <div :class="$style.kv">
        <p :class="$style.kvTitle">其他信息</p>
        <kvbox
          v-model="add.others_info"
          :placeholder="{ k: '请输入标题，如“游玩时间”', v: '请输入描述内容。如“1~3小时”' }"
        />
      </div>
    </div>
    <div :class="$style.realtion">
      <p :class="$style.title">关联信息</p>
      <div class="layout">
        <div class="label">文化点关联</div>
        <div class="value">
          <div class="line-40">
            <BjButton @click="onAddPoint()"><i class="ri-focus-line left" />选择文化点</BjButton>
          </div>
          <div :class="$style.pocBox">
            <div v-for="(item, index) in add.relation_poc_info" :key="item.id" :class="$style.pocItem">
              <div :class="$style.pocPad">
                <div :class="$style.img">
                  <img :src="item.cover.cover" />
                </div>
                <div :class="$style.name">
                  <p :title="item.name" class="ellipsis-width" :class="$style.title">{{ item.name }}</p>
                  <p :title="item.poc_type_name" class="ellipsis-width">{{ item.poc_type_name }}</p>
                </div>
                <div :class="$style.btn">
                  <i class="ri-eye-line primary" @click="onPreview(item)" />
                  <i class="ri-delete-bin-7-line" @click="onDeletePoc(index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton class="mr-10" type="primary" @click="onSave()">
        <i class="ri-save-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCannel()">取消添加</BjButton>
    </div>
    <BjMap :visible.sync="visible.map" @change="mapChange" />
    <choosePoint :visible.sync="visible.point" :select="add.relation_poc_info" :multiple="true" @change="pointChange" />
  </div>
</template>

<script>
import BjEditor from '@/components/BjEditor'
import BjMap from '@/components/BjMap.vue'
import choosePoint from '@/components/choosePoint'
import selectMaterial from '@/components/selectMaterial'
import { appVenueService } from '@/service'

import kvbox from './kvbox.vue'

const service = new appVenueService()

export default {
  name: 'Home',
  components: {
    selectMaterial,
    BjMap,
    BjEditor,
    kvbox,
    choosePoint,
  },
  data() {
    return {
      visible: {
        map: false,
        point: false,
      },
      add: {
        name: null,
        cover: [],
        contact_phone: null,
        summary: null,
        status: 1,
        comment_status: 1,
        tags: [],
        open_time: [],
        preferential: [],
        others_info: [],
        location: null,
        real_location: null,
        longitude: null,
        latitude: null,
        adcode: null,
        relation_poc_info: [],
      },
      groupData: [],
      tagShow: true,
      tag: null,
      id: null,
      footWidth: '800px',
    }
  },
  created() {
    this.getGroup()
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    } else {
      this.id = null
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getDetail({
          id: this.id,
        })
        this.add = data
      } catch (e) {}
    },
    async getGroup() {
      const { data } = await service.getGroup()
      this.groupData = data
    },
    coverChange(data) {
      if (data.length > 10) {
        this.$message.error('最多10张封面')
        this.add.cover = data.splice(0, 10)
        return
      }
      this.add.cover = data
    },
    onMapSelect() {
      this.visible.map = true
    },
    mapChange(e) {
      this.add.location = e.location
      this.add.real_location = e.location
      this.add.longitude = e.longitude
      this.add.latitude = e.latitude
      this.add.adcode = e.adcode
    },
    tagChange() {
      this.tagShow = true
      if (this.tag) {
        this.add.tags.push(this.tag)
      }
      this.tag = null
    },
    onAddPoint() {
      this.visible.point = true
    },
    pointChange(data) {
      this.add.relation_poc_info = data
    },
    deleteTag(index) {
      this.add.tags.splice(index, 1)
    },
    async onSave() {
      try {
        this.add.relation_poc = this.add.relation_poc_info.map(item => item.id)
        if (this.id) {
          await service.edit({
            ...this.add,
            id: this.id,
          })
        } else {
          await service.add({
            ...this.add,
          })
        }
        this.$message.success(this.id ? '编辑成功' : '添加成功')
        this.$router.push({
          name: 'appVenueManage',
        })
      } catch (e) {}
    },
    onCannel() {
      this.$router.push({
        name: 'appVenueManage',
      })
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: item.id,
        },
      })
    },
    onDeletePoc(index) {
      this.add.relation_poc_info.splice(index, 1)
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .title {
    color: #000;
    font-size: 16px;
  }

  .box {
    width: 750px;
  }

  .base {
    padding: 20px;
    background: #fff;
  }

  .desc {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }

  .realtion {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;

    .poc-box {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      width: 100%;
      margin-top: 10px;

      .poc-pad {
        display: flex;
        padding: 0 10px;
        padding: 5px;
        border: 1px solid #eee;
      }

      .poc-item:nth-child(3n + 1) {
        padding-right: 10px;
      }

      .poc-item:nth-child(3n + 2) {
        padding: 0 10px;
      }

      .poc-item:nth-child(3n + 0) {
        padding-left: 10px;
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .poc-item {
        width: 33.3%;
        height: 70px;
        margin-bottom: 20px;

        .img {
          position: relative;
          width: 60px;
          height: 60px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          flex: 1;
          padding-top: 5px;
          padding-left: 10px;

          p {
            margin-bottom: 6px;
            color: #5c5c5c;
            font-size: 12px;
          }

          .title {
            color: #000;
            font-size: 14px;
          }
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .btn {
          width: 50px;
          padding-top: 16px;

          i {
            margin-right: 10px;
            font-size: 18px;
          }
        }
      }
    }
  }

  .locationbtn {
    display: inline-block;
    width: 84px;
    height: 28px;
    color: #fff;
    font-size: 12px;
    line-height: 28px !important;
    text-align: center;
    background: @primary-color;
    border-radius: 6px;
    cursor: pointer;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      margin-right: 3px;
      color: #fff;
      font-size: 16px;
      vertical-align: -3px;
    }
  }

  .location {
    :global {
      .ant-form-item {
        margin-bottom: 5px !important;
      }

      .ant-input {
        padding-right: 125px !important;
      }
    }
  }

  .tag-box {
    display: flex;
    padding: 5px;
    border: 1px solid #eee;

    .tag-item {
      flex: 1;
      /* stylelint-disable-next-line no-descending-specificity */
      p {
        margin: 0;
        margin-left: 6px;
        color: #bbb;
        line-height: 26px;
      }

      .tag-text {
        position: relative;
        display: inline-block;
        width: 66px;
        height: 24px;
        margin: 2px 10px 0 0;
        color: #5c5c5c;
        font-size: 10px;
        text-align: center;
        background: #eee;
        border-radius: 4px;

        .text {
          margin-top: 3px;
          padding: 0 10px 0 3px;
        }
        /* stylelint-disable-next-line no-descending-specificity */
        i {
          position: absolute;
          top: 2px;
          right: 3px;
          font-size: 14px;
        }
      }
    }

    .tag-input {
      :global {
        .ant-input {
          width: 84px;
          height: 28px;
          border-radius: 6px;
        }
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .location {
      display: inline-block;
      width: 84px;
      height: 28px;
      color: #fff;
      font-size: 12px;
      line-height: 28px;
      text-align: center;
      background: @primary-color;
      border-radius: 6px;
      cursor: pointer;
      /* stylelint-disable-next-line no-descending-specificity */
      i {
        font-size: 16px;
        vertical-align: -3px;
      }
    }
  }

  .area {
    position: relative;

    .num {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #5c5c5c;
    }
  }

  .line {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid #eee;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 880px;
  height: 60px;
  padding: 0 20px;
  line-height: 60px;
  background: #fff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
}
</style>
