<template>
  <div :class="$style.box">
    <Draggable v-model="data" ghost-class="ghost" handle=".mover">
      <div v-for="(item, index) in data" :key="index" :class="$style.item">
        <img :src="oss + '/manage/icon/default/sort.png'" class="mover" :class="$style.move" />
        <a-input v-model="item.k" :class="$style.key" :placeholder="placeholder.k" />
        <a-input v-model="item.v" :class="$style.value" :placeholder="placeholder.v" />
        <i :class="$style.delete" class="ri-close-circle-line" @click="onDelete(index)" />
      </div>
    </Draggable>
    <bj-button type="dashed" @click="onAdd()">
      <i class="ri-add-line left" />
      添加一条
    </bj-button>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'kvbox',
  components: {
    Draggable,
  },
  model: {
    prop: 'data',
    event: 'returnBackFn',
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: Object,
      default: () => ({
        k: '',
        v: '',
      }),
    },
  },
  data() {
    return {}
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    returnBackFn() {
      this.$emit('change', this.data)
    },
    onAdd() {
      this.data.push({
        k: null,
        v: null,
      })
    },
    onDelete(index) {
      this.data.splice(index, 1)
    },
  },
}
</script>

<style lang="less" module>
.box {
  .item {
    width: 750px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .move {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      opacity: 0;
    }

    .key {
      width: 240px;
      margin-right: 10px;
    }

    .value {
      flex: 1;
    }

    .delete {
      font-size: 20px;
      color: @error-color;
      margin-left: 10px;
    }
  }

  .item:hover {
    .move {
      opacity: 1;
    }
  }
}
</style>
